import axios from 'axios'
import store from '@/store'
import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 6000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = error => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    const token = storage.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      notification.error({
        message: '被禁止的!',
        description: data.message
      })
    }
    if (
      error.response.status === 401 &&
      !(data.result && data.result.isLogin)
    ) {
      notification.error({
        message: '未经授权的:',
        description: '授权验证失败！'
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = storage.get(ACCESS_TOKEN).replace(/"/g, '')
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  config.headers['Content-Type'] = 'application/json'
  config.headers['X-Application-name'] = 'app'
  config.headers['VERSION'] = localStorage.getItem('VERSION')
  config.headers['countryCode'] = localStorage.getItem('countryCode')
  config.headers['countryId'] = localStorage.getItem('countryId')

  if (token) {
    config.headers[ACCESS_TOKEN] = token
    config.headers['Authorization'] = 'bearer ' + token
  }

  // if (localStorage.getItem('VERSION')) {
  //   if (config.params) {
  //     config.params['VERSION'] = localStorage.getItem('VERSION')
  //   } else {
  //     config.params = { VERSION: localStorage.getItem('VERSION') }
  //   }
  // }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use(response => {
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export { installer as VueAxios, request as axios }
