<script>
import map from './map'
import style from './style'
import control from './control'
import event from './event'
import drawer from './drawer'
import config from './config'
import net from './net.vue'
import { message } from 'ant-design-vue'
import apiTool from '@/command/apiTool'
export default {
  mixins: [map, style, control, event, drawer, config, net],
  props: {
    checkedKeys: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 主体信息
      dkInfo: null,
      // 当前图层数据
      drawLayer: {},
      // 当前选择的按钮
      selectIndex: 0,
      // 搜索数据列表
      fixSelectData: [],
      // 当前所处模式
      mode: 'edit',
      // 当前是否结束绘制
      isDrawEnd: true,
      // 当前地块数据
      currentDKInfo: null,
      // 地块选中数据
      dkSelect: {},
      // 当前图层的json数据
      layerJSON: {},
      // 是否结束
      isFinish: true,
      // 当前绘制图层数组
      drawLayerList: [],
    }
  },
  watch: {
    checkedKeys(newValue) {
      // 提取树形中的主体类型跟区域类型
      let bodyType = []
      let streetCode = []
      newValue.forEach((e) => {
        if (e.data.props.treeType == 0) {
          bodyType.push(e.data.props.id)
        } else {
          streetCode.push(e.data.props.id)
        }
      })
      this.initMapLayer(bodyType, streetCode)
    },
  },
  mounted() {
    // 初始化地图
    this.initMap()
    // 图层事件监听
    this.initEvent()
  },
  methods: {
    // 获取当前选中图层的最后一条数据
    getSelectLastData() {
      const selects = this.getAllSelectLayer()
      const lastSelect = selects[selects.length - 1]
      return lastSelect
    },
    // 获取当前缓存图层
    getCacheLayer(asGroup = true) {
      return this.map.pm.getGeomanLayers(asGroup)
    },
    getCacheJSON() {
      return this.getCacheLayer().toGeoJSON()
    },
    // 断言 判断当前是否绘制完成
    assertDrawEnd(index) {
      if (this.selectIndex != index) {
        if (!this.isFinish) {
          message.error('请先完成绘制')
          return false
        } else {
          this.resetDraw()
          this.resetSelectStyle()
          this.selectIndex = index
          this.dkSelect = {}
          return true
        }
      } else {
        message.warning('请勿重复点击')
        return false
      }
    },
    // 获取layer属性
    getLayerAttr(layer) {
      return layer.toGeoJSON ? layer.toGeoJSON().properties : layer.properties
    },
    // 初始化地图geojson图层
    initMapLayerGeoJson(json) {
      console.log('initMapLayerGeoJson', json)
      if (this.layer) {
        this.layer.remove()
      }
      // if (json && json.features) {
      //   this.drawLayerList = json.features
      // } else {
      //   this.drawLayerList = []
      // }
      this.layer = new L.geoJSON(json || null, this.getLayerConfig()).on('click', this.onLayerClick).addTo(this.map)
    },
    // 设置当前模式
    setMode(mode) {
      this.mode = mode
    },
  },
}
</script>
