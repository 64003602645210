<script>
import { Cascader } from 'ant-design-vue'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    typeData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      optionsArr: [],
    }
  },
  mounted() {
    this.optionsArr = this.typeData
  },
  methods: {
    change(value, selectedOptions) {
      let labelText = null
      if (selectedOptions) {
        labelText = selectedOptions.map((o) => o.label).join('/')
      }
      this.$emit('change', value, labelText)
    },
    filter(inputValue, path) {
      return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    },
  },
  render() {
    return (
      <Cascader
        onChange={this.change}
        value={this.value}
        options={this.optionsArr}
        allowClear
        placeholder={'产品类别选择'}
        showSearch={this.filter}
        changeOnSelect
        style="width: 100%"
      />
    )
  },
}
</script>
<style lang="less" scoped>
.form-cascader {
  width: 100%;
}
</style>
