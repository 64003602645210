<script>
import { message } from 'ant-design-vue'
import apiTool from '@/command/apiTool'
export default {
  methods: {
    // 拆分模式
    splitLine(layer) {
      const lastSelect = this.getSelectLastData()
      const lastSelectJson = lastSelect.toGeoJSON()
      try {
        var clippedPolygon = geoUtil.polygonClipByLine(lastSelectJson, layer.toGeoJSON())
        this.layer.addData({
          type: 'FeatureCollection',
          features: this.clippedPolygonJSON(clippedPolygon.features, lastSelectJson),
        })
        this.deleteLayer(lastSelect)
        layer.remove()
      } catch (error) {
        message.error(error.message)
      }
    },
    clippedPolygonJSON(data, select) {
      return data.map((layer) => {
        const point = turf.center(layer)
        const json = select.properties
        return {
          type: 'FeatureCollection',
          features: [
            {
              ...layer,
              properties: {
                ...json,
                id: apiTool.getUUID(),
              },
            },
            {
              ...point,
              properties: {
                ...json,
                id: apiTool.getUUID(),
              },
            },
          ],
        }
      })
    },
    // 开始画多边形
    startPolygon() {
      setTimeout(() => {
        this.map.pm.enableDraw('Polygon', {
          snappable: true,
          snapDistance: 20,
        })
      }, 500)
    },
    // 绘制线条
    drawLine() {
      setTimeout(() => {
        this.map.pm.enableDraw('Line', {
          snappable: true,
          snapDistance: 20,
        })
      }, 500)
    },
    // 重置绘图模式
    resetDraw() {
      this.map.pm.disableDraw()
      this.map.pm.disableGlobalEditMode()
    },
    // 开始画线
    startLine() {
      const allSelect = this.getAllSelectLayer()
      if (allSelect.length == 0) {
        message.warning('请先点击一个地块,并双击画线拆分地块')
        return
      } else if (allSelect.length > 1) {
        message.warning('请只选择一个地块')
        return
      }
    },
    // 合并
    startMerge() {
      message.warning('请选择一个以上的地块进行合并')
    },
  },
}
</script>
