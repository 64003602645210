import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'

// default router permission control
import permission from './modules/permission'

// dynamic router permission control (Experimental)
// import permission from './modules/async-router'
import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    permission,
  },
  state: {
    categoryArr: [], //主体类型列表
    scjyArr: [], //生产者经营类别列表
    sctypeArr: [], //产业类型列表
  },
  mutations: {
    setCategoryArr: (state, data) => {
      state.categoryArr = data
    },
    setScjyArr: (state, data) => {
      state.scjyArr = data
    },
    setSctypeArr: (state, data) => {
      state.sctypeArr = data
    },
  },
  actions: {},
  getters,
})
