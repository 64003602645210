<script>
const SELECT_DEFAULT = {
  color: 'white',
  fillColor: 'white',
  fillOpacity: 0.5,
}
export default {
  methods: {
    getMainBodyStyle(name) {
      const style = {
        '农业专业合作社':{
          color:'rgba(255, 51, 51, 0.5)',
          fillColor:'#FF3333'
        },
        '农业相关企业':{
          color:'rgba(26, 102, 255, 0.5)',
          fillColor:'#1A66FF'
        },
        '家庭农场':{
          color:'rgba(0, 255, 85, 0.5)',
          fillColor:'#00FF55'
        },
        '其他规模主体':{
          color:'rgba(255, 85, 0, 0.5)',
          fillColor:'#FF5500'
        },
        '农民专业合作社':{
          color:'#FF00FF',
          fillColor:'rgba(255, 0, 255, 0.5)'
        }
      }
      if (style[name]) {
        return { ...style[name], fillOpacity: 0.5, fill: true }
      } else {
        return { color: 'rgba(255, 255, 255, 1)', fillColor: '#00FFFF' }
      }
    },
    // 恢复所有选中为默认风格
    resetSelectStyle() {
      this.getAllSelectLayer().forEach((item) => {
        const style = this.getLayerAttr(item)
        item.setStyle(style)
      })
    },
    // 重置单条样式
    resetSingleStyle(layer) {
      console.log('layer',layer);
      layer.setStyle(this.getLayerAttr(layer))
    },
    // 设置当前选中
    setSelectStyle(layer) {
      layer.setStyle(SELECT_DEFAULT)
    },
  },
}
</script>
