<script>
import { Upload } from 'ant-design-vue'
export default {
  props: ['value', 'item', 'mode', 'styles'],
  methods: {
    handlePreview() {},
    getImgUrl(list) {
      // '?imageView2/0/format/jpg/q/75'
      const arr = []
      const { url } = JSON.parse(localStorage.getItem('qiniu')) || {}
      list.forEach((e) => {
        if (e.status == 'done') {
          if (e.hash) {
            arr.push(url + e.hash)
          } else if (e.url) {
            arr.push(e.url)
          } else {
            if (e.response && e.response.hash) {
              arr.push(url + e.response.hash)
            }
          }
        }
      })
      return arr.join(',')
    },
    getFileList(url) {
      if (Array.isArray(url)) return url
      if (!url) return []
      return url
        .split(',')
        .filter((e) => e)
        .map((e) => {
          if (!e) return
          const name = e.split('?')[0].split('/')[3]
          return {
            uid: 'vc-upload-' + name,
            name,
            status: 'done',
            url: e,
            thumbUrl: e,
          }
        })
    },
    handleChange(data) {
      if (data.file.status == 'uploading') {
        this.$emit('change', data.fileList)
      } else {
        this.$emit('change', this.getImgUrl(data.fileList))
      }
    },
    renderButton() {
      return (
        <div>
          <a-icon type="plus" />
          <div class="ant-upload-text">上传</div>
        </div>
      )
    },
    renderUploadItem(file) {
      if (file.indexOf('mp4') !== -1) {
        return <video src={file} />
      }
      return <img src={file} />
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
      if (!isJpgOrPng) {
        this.$message.error('请上传图片文件（jpeg/png/jpg）')
        return false
      }
      const isLt4M = file.size / 1024 / 1024 < 5.5
      if (isJpgOrPng && !isLt4M) {
        this.$message.error('图片资源文件体积不能超过5.5M')
      }
      return isJpgOrPng && isLt4M
    },
  },
  render() {
    const { token } = JSON.parse(localStorage.getItem('qiniu')) || {}
    return (
      <div>
        <Upload
          name="file"
          action="//upload.qiniup.com/"
          list-type="picture-card"
          fileList={this.getFileList(this.value)}
          accept={'text/img'}
          disabled={this.mode == 'detail'}
          class="uploader"
          data={{ token }}
          onChange={this.handleChange}
          style={this.styles}
          renderUploadItem={this.renderUploadItem}
          beforeUpload={this.beforeUpload}
        >
          {(this.getFileList(this.value) || []).length < (this.item.maxLength || 1) && this.renderButton()}
        </Upload>
        {this.item.descTitle && (
          <div class="desc-box" style={this.item.styles}>
            <span>{this.item.descTitle}</span>
          </div>
        )}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.uploader {
  margin-left: 0px !important;
}
.desc-box {
  width: 100%;
  text-align: center;
  height: 20px;
  margin-top: -20px;
}
</style>
