<script>
import 'leaflet-wfst'
import { message } from 'ant-design-vue'
export default {
  methods: {
    // 初始化地图
    initMap() {
      this.map = L.map(this.$refs.map, {
        crs: L.CRS.EPSG4326,
        center: [30.2, 121.3],
        zoom: 13,
        layers: [this.getLayers()],
        zoomControl: false,
        // 禁用双击缩放
        doubleClickZoom: true,
        renderer: L.canvas(),
      })
      this.map.invalidateSize(true)
      this.map.pm.setLang('zh')
      this.map.on('click', this.onSingleClick)
    },
    onSingleClick(data) {
      if (this.layerClick) {
        this.layerClick = false
      } else {
        if (!this.dkInfo) {
          message.error('请先选择主体')
          return
        }
        var size = this.map.getSize()
        var params = {
          request: 'GetFeatureInfo',
          srs: 'EPSG:4326',
          styles: '',
          format: 'jpeg',
          bbox: this.map.getBounds().toBBoxString(),
          height: size.y,
          width: size.x,
          layers: 'cixi:DK3302822021',
          query_layers: 'cixi:DK3302822021',
          info_format: 'application/json',
          width: size.x,
          height: size.y,
          x: data.containerPoint.x,
          y: data.containerPoint.y,
        }
        var url = '/geoserver/cixi/wms' + L.Util.getParamString(params)
        fetch(url)
          .then((e) => e.json())
          .then((e) => {
            if (e.features.length > 1) {
              message.error('选择了多个地块 请调整缩放')
            } else if (e.features.length == 0) {
              message.error('请准确选择地块数据')
            } else {
              this.isFinish = false
              const json = {
                ...e.features[0].properties,
                type: 'new',
                id: e.features[0].id,
                ...this.dkInfo,
                ...this.getMainBodyStyle(this.dkInfo.mainBodyTypeName),
              }
              e.features[0].properties = json
              this.layer.addData(e)
              const keys = Object.keys(this.layer['_layers'])
              const lastKey = keys[keys.length - 1]
              this.$set(this.dkSelect, lastKey, this.layer['_layers'][lastKey])
            }
          })
      }
    },
    // 绘制选中地块
    drawSelectDk() {
      this.initMapLayerGeoJson(this.selectDk)
    },
    // 获取卫星地图图层
    getLayers() {
      return L.layerGroup([
        L.tileLayer(
          'https://service-jz2zomdh-1257443019.sh.apigw.tencentcs.com/services/wmts/imgmap/2021/oss??SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=c&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&token=5e083bd37263c80781fff960e8f5e655',
          {
            zoomOffset: 1,
          }
        ),
        // L.tileLayer(
        //   'https://service-jz2zomdh-1257443019.sh.apigw.tencentcs.com/mapserver/vmap/WMTS/1.0/zjvmap/tdt_biaozhunyangshi_2017??SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=c&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&token=5e083bd37263c80781fff960e8f5e655',
        //   {
        //     zoomOffset: 1,
        //   }
        // ),
        L.tileLayer(
          '/geoserver/gwc/service/wmts?layer=cixi:DK3302822021&style=&tilematrixset=EPSG%3A4326&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TILEMATRIX=EPSG:4326:{z}&TILEROW={y}&TILECOL={x}'
        ),
        L.tileLayer(
          'https://service-jz2zomdh-1257443019.sh.apigw.tencentcs.com/services/wmts/imgmap_lab/default/oss??SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=c&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&token=5e083bd37263c80781fff960e8f5e655',
          {
            zoomOffset: 1,
          }
        ),
      ])
    },
  },
}
</script>
