export const handleDeep = function (data) {
  return data.map((e) => {
    return {
      ...e,
      title: e.name,
      value: e.id,
      key: e.id,
      children: e.children && e.children.length > 0 && handleDeep(e.children),
    }
  })
}

export const handleDeep2 = function (data) {
  return data.map((e) => {
    return {
      ...e,
      name: e.name,
      value: e.id,
    }
  })
}

export const handleDeep3 = function (data) {
  return data.map((e) => {
    return {
      ...e,
      label: e.name,
      value: String(e.id),
      children: e.children && e.children.length > 0 && handleDeep3(e.children),
    }
  })
}

export const handleDeep4 = function (data) {
  return data.map((e) => {
    return {
      ...e,
      label: e.name,
      value: String(e.id),
      children: e.children && e.children.length > 0 && handleDeep4(e.children),
    }
  })
}
