<script>
export default {
  methods: {
    // 获取图层配置
    getLayerConfig() {
      return {
        pointToLayer(geoJsonPoint, latlng) {
          const name = (geoJsonPoint.properties || {}).dkName || ''
          var myIcon = L.divIcon({
            className: 'my-div-icon',
            html: `<div id="${geoJsonPoint.properties.id}">${name}</div>`,
          })
          return L.marker(latlng, { icon: myIcon })
        },
        style: function (feature) {
          if (feature && feature.properties) {
            return { ...feature.properties, fillOpacity: 0.5, fill: true }
          } else {
            return null
          }
        },
      }
    },
  },
}
</script>
