import { BasicLayout } from '@/layouts'
// import { goods } from '@/core/icons'

const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view'),
}

function getHidden() {
  return [
    {
      path: '/exchange/detail',
      name: 'exchangeDetail',
      component: () => import('@/views/exchange/detail'),
      hidden: true,
      meta: {
        title: '数据交换详情',
      },
    },
    {
      path: '/basicInformation/detail',
      name: 'basicInformationDetail',
      component: () => import('@/views/basicInformation/detail'),
      hidden: true,
      meta: {
        title: '主体基本信息详情',
      },
    },
    {
      path: '/iot/detail',
      name: 'iotDetail',
      component: () => import('@/views/iot/detail'),
      hidden: true,
      meta: {
        title: '物联网设备详情',
      },
    },
    {
      path: '/basicInformation/detailCold',
      name: 'detailCold',
      component: () => import('@/views/basicInformation/detailCold'),
      hidden: true,
      meta: {
        title: '冷库',
      },
    },
    {
      path: '/basicInformation/detailColdAdd',
      name: 'detailColdAdd',
      component: () => import('@/views/basicInformation/detailColdAdd'),
      hidden: true,
      meta: {
        title: '冷库详情',
      },
    },
  ]
}
export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: {
      title: '首页',
    },
    redirect: '/basicInformation',
    children: [
      ...getHidden(),
      {
        path: '/basicInformation',
        name: 'basicInformation',
        component: () => import('@/views/basicInformation/index'),
        meta: {
          title: '主体基本信息',
          icon: (h) => {
            return (
              <span style={{ display: 'block' }}>
                <img src={require('./asset/icon1.png')} style={{ width: '15px', height: '15px', marginTop: '-5px' }} />
              </span>
            )
          },
          keepAlive: false,
        },
      },

      {
        path: '/iot',
        name: 'iot',
        component: () => import('@/views/iot/index'),
        meta: {
          title: '物联网设备管理',
          icon: (h) => {
            return (
              <span style={{ display: 'block' }}>
                <img src={require('./asset/icon5.png')} style={{ width: '15px', height: '15px', marginTop: '-5px' }} />
              </span>
            )
          },
          keepAlive: false,
        },
      },
      {
        path: '/exchange',
        name: 'exchange',
        component: () => import('@/views/exchange/index'),
        meta: {
          title: '数据交换',
          icon: (h) => {
            return (
              <span style={{ display: 'block' }}>
                <img src={require('./asset/icon4.png')} style={{ width: '15px', height: '15px', marginTop: '-5px' }} />
              </span>
            )
          },
          keepAlive: false,
        },
      },
      {
        path: '/face',
        name: 'face',
        component: () => import('@/views/face/index'),
        meta: {
          title: '人脸信息',
          icon: (h) => {
            return (
              <span style={{ display: 'block' }}>
                <img src={require('./asset/icon2.png')} style={{ width: '15px', height: '15px', marginTop: '-5px' }} />
              </span>
            )
          },
        },
      },
      {
        path: '/map',
        name: 'map',
        component: () => import('@/views/map/index'),
        meta: {
          title: '地块信息',
          icon: (h) => {
            return (
              <span style={{ display: 'block' }}>
                <img src={require('./asset/icon3.png')} style={{ width: '15px', height: '15px', marginTop: '-5px' }} />
              </span>
            )
          },
        },
      },
    ],
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true,
  },
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: RouteView,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login'),
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register'),
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult'),
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined,
      },
    ],
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/404'),
  },
]
