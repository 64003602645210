<script>
import { Dropdown, Menu, Table } from 'ant-design-vue'
import LayerGroup from './layerGroup'
import { getAction, postAction } from '@/command/netTool'
export default {
  mixins: [LayerGroup],
  mounted() {
    this.initMapLayer()
  },
  data() {
    return {
      fixList: [],
      fixSelect: {
        name: '',
      },
    }
  },
  methods: {
    initMapLayer(bodyType = [], streetCode = []) {
      this.getLayerData().then((json) => this.initMapLayerGeoJson(json))
    },
    setCenter(center) {
      this.map.setView([center[1], center[0]], 18)
    },
    renderButtonGroup() {
      const data = [
        // {
        //   name: '重置测试',
        //   key: -1,
        //   onClick: () => this.resetAll(),
        // },
        {
          name: '编辑模式',
          key: 0,
          onClick: () => this.assertDrawEnd(0) && this.resetDraw(),
        },
        {
          name: '画线',
          key: 1,
          display: this.mode != 'edit',
          onClick: () => this.assertDrawEnd(1) && this.startPolygon(),
        },
        {
          name: '拆分',
          key: 2,
          onClick: () => this.assertDrawEnd(2) && this.startLine(),
        },
        {
          name: '合并',
          key: 3,
          onClick: () => this.assertDrawEnd(3) && this.startMerge(),
        },
      ].filter((e) => (e.display != undefined ? e.display : true))
      return (
        <div class="mapview-fix-group">
          {data.map((e, i) => {
            return (
              <div class="mapview-fix-button" data-active={this.selectIndex == e.key} onClick={e.onClick}>
                {e.name}
              </div>
            )
          })}
        </div>
      )
    },
    renderSaveButton() {
      const data = [
        {
          name: '取消',
          onClick: this.onCancel,
        },
        {
          name: '保存',
          onClick: this.onSave,
        },
      ]
      return (
        <div class="mapview-fix-group" style={{ top: '90px', height: '40px' }}>
          {data.map((e) => {
            return (
              <div class="mapview-fix-button" onClick={e.onClick}>
                {e.name}
              </div>
            )
          })}
        </div>
      )
    },
    onSelectInputSearch(value) {
      if (value == '') {
        this.initMapLayer()
      } else {
        getAction('/api/dataoperation/collectionMainBody/namePage?name=' + value).then((json) => {
          this.fixList = json.records
        })
      }
    },
    onMenuClick(item) {
      if (!this.isFinish) {
        message.error('请先完成绘制')
        return false
      } else {
        getAction('/api/dataoperation/collectionMainDk/findDk?allName=' + item.mainName).then((json) => {
          this.initMapLayerGeoJson(json)
          this.visible = false
        })
      }
    },
    // 搜索列表
    renderFixInput() {
      return (
        <div class="map-view-fix-input">
          <Dropdown visible={this.visible}>
            <input
              v-model={this.fixSelect.mainName}
              placeholder={'搜索主体名称/法人姓名/街道名/主体类型'}
              onFocus={() => (this.visible = true)}
              onBlur={() => (this.visible = false)}
              onInput={(e) => this.onSelectInputSearch(e.target.value)}
            />
            <template slot="overlay">
              <Menu>
                {this.fixList.map((e) => {
                  return (
                    <Menu.Item key={e.value} onClick={() => this.onMenuClick(e)}>
                      {e.mainName}
                    </Menu.Item>
                  )
                })}
              </Menu>
            </template>
          </Dropdown>
        </div>
      )
    },
    getModeShow() {
      if (this.mode == 'edit') {
        return this.drawLayerList.length > 0
      } else {
        return true
      }
    },
    // 主体点定位
    setZTLocation(data) {
      if (data.lat && data.lng) {
        if (this.markerLayer) {
          this.markerLayer.remove()
        }
        this.markerLayer = L.marker([data.lat, data.lng]).addTo(this.map)
        setTimeout(() => {
          this.map.setView([data.lat, data.lng], 18)
        }, 500)
      }
    },
    // 设置主体信息
    setZTInfo(data) {
      this.setZTLocation(data)
      this.dkInfo = data
    },
    renderModal() {
      const last = this.getSelectLastData()
      const data = [
        {
          title: '地块名称',
          dataIndex: 'DKMC',
        },
        {
          title: '证件人姓名',
          dataIndex: 'ZJRXM',
        },
        {
          title: '面积(平方米)',
          dataIndex: 'SCMJ',
        },
        {
          title: '实测面积(亩)',
          dataIndex: 'SCMJM',
        },
      ]
      return (
        <div class="map-view-table">
          <Table columns={data} dataSource={[last.options]} pagination={false} />
        </div>
      )
    },
  },
  render() {
    return (
      <div class="map-view-main">
        <div ref="map" id="map" />
        {this.getModeShow() && this.renderButtonGroup()}
        {!this.isFinish && this.renderSaveButton()}
        {this.renderFixInput()}
        {this.getSelectLastData() && this.renderModal()}
      </div>
    )
  },
}
</script>
<style lang="less">
.my-div-icon {
  white-space: nowrap;
  color: white;
  font-size: 12px;
  pointer-events: none !important;
}
</style>
<style lang="less" scoped>
.map-view-main {
  display: flex;
  position: relative;
  flex: 1;
  #map {
    width: 100%;
    height: 100%;
  }
}
.mapview-fix-group {
  position: absolute;
  top: 30px;
  right: 30px;
  display: flex;
  align-items: center;
  z-index: 999;
  height: 49px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  width: 150px;
  .mapview-fix-button {
    font-size: 14px;
    color: #2665fa;
    font-weight: bold;
  }
  & > div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    &[data-active='true'] {
      color: #2665fa;
      font-weight: bold;
      font-size: 15px;
    }
    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        right: 0px;
        top: 0px;
        width: 1px;
        height: 20px;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}
.map-view-fix-input {
  position: absolute;
  left: 30px;
  top: 30px;
  display: flex;
  align-items: center;
  height: 48px;
  width: 350px;
  background-color: white;
  border-radius: 8px;
  z-index: 999;
  /deep/.ant-select {
    width: 100%;
    border: none;
  }
  /deep/.ant-select-selection {
    border: none !important;
    box-shadow: none !important;
  }
  /deep/.ant-select-focused {
    border: none !important;
  }
  input {
    outline: none;
    border: none;
    padding-left: 20px;
    width: 100%;
    &:active {
      outline: none;
    }
  }
}
.map-view-table {
  position: absolute;
  left: 30px;
  bottom: 30px;
  width: 666px;
  height: auto;
  background: white;
  padding: 10px;
  z-index: 9999;
  border-radius: 8px;
}
</style>
