<script>
import { message } from 'ant-design-vue'
import apiTool from '@/command/apiTool'
export default {
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeyDown)
  },
  methods: {
    // 初始化监听图层事件
    initEvent() {
      // 绘制开始回调
      this.map.on('pm:drawstart', () => {
        this.isDrawEnd = false
        this.isFinish = false
        console.log('落笔')
      })
      this.map.on('pm:create', this.onPMCreate)
      // 键盘按下回调
      window.addEventListener('keydown', this.onKeyDown)
    },
    onPMCreate({ shape, layer }) {
      if (shape == 'Line') {
        this.splitLine(layer)
        this.resetSelectStyle()
        this.resetDraw()
        this.dkSelect = {}
      } else {
        this.layer.addData(this.mergetJSON(layer))
        layer.remove()
      }
      if (this.selectIndex == 1) {
        this.startPolygon()
        return
      }
      if (this.selectIndex == 2) {
      }
    },
    getInfoData() {
      return {
        ...this.dkInfo,
        ...this.getMainBodyStyle(this.dkInfo.mainBodyTypeName),
      }
    },
    mergetJSON(layer) {
      const latlng = layer.getCenter()
      const json = layer.toGeoJSON()
      const data = this.getInfoData()
      return {
        type: 'FeatureCollection',
        features: [
          {
            ...json,
            properties: data,
          },
          {
            ...json,
            properties: data,
            geometry: {
              type: 'Point',
              coordinates: [latlng.lng, latlng.lat],
            },
          },
        ],
      }
    },
    // 键盘按下事件
    onKeyDown(data) {
      if ((data.code == 'Delete' || data.code == 'Backspace') && data.target.localName != 'input') {
        const lastSelect = this.getSelectLastData()
        if (lastSelect) {
          this.isFinish = false
          this.$delete(this.dkSelect, lastSelect._leaflet_id)
          this.deleteLayer(lastSelect)
          message.success('删除成功')
        } else {
          message.error('没有可删除的数据')
        }
      }
    },
    // 图层被单击
    onLayerClick({ layer }) {
      this.layerClick = true
      this.isFinish = false
      if (layer.options.type == 'new') {
        layer.remove()
      } else {
        if (this.dkSelect[layer._leaflet_id]) {
          this.$delete(this.dkSelect, this.dkSelect[layer._leaflet_id])
          delete this.dkSelect[layer._leaflet_id]
          this.resetSingleStyle(layer)
        } else {
          this.$set(this.dkSelect, layer._leaflet_id, layer)
          this.setSelectStyle(layer)
        }
      }
      // // 如果当前为创建模式下 并且当前选择的地块并不是刚创建出来的则提示 否则重置全部样式 并设置当前选中样式为
      // // 当前没有选择任何地块 则先添加一条
      // this.isFinish = false
      // // 编辑模式
      // if (this.selectIndex == 0) {
      //   this.resetSelectStyle()
      //   this.resetDraw()
      //   if (this.dkSelect[layer._leaflet_id]) {
      //     delete this.dkSelect[layer._leaflet_id]
      //     this.$emit('modalChange', { state: false })
      //   } else {
      //     this.dkSelect = { [layer._leaflet_id]: layer }
      //     // this.setEditMode(layer)
      //     this.setSelectStyle(layer)
      //     if (this.mode == 'edit') {
      //       this.dkInfo = layer.toGeoJSON().properties
      //       this.$emit('modalChange', { state: true, data: this.dkInfo })
      //     }
      //   }
      // } else if (this.selectIndex == 2) {
      //   // 先判断当前是否有数据 如果没有数据就添加 如果有数据判断是不是之前点击的那条 如果是就取消选中 如果不是就提示 并且继续操作
      //   if (this.getAllSelectLayer().length == 0) {
      //     this.$set(this.dkSelect, layer._leaflet_id, layer)
      //     this.setSelectStyle(layer)
      //     if (this.selectIndex == 2) {
      //       this.drawLine()
      //     }
      //   } else {
      //     if (this.dkSelect[layer._leaflet_id]) {
      //       this.resetSelectStyle()
      //       this.resetDraw()
      //       delete this.dkSelect[layer._leaflet_id]
      //     } else {
      //       this.resetDraw()
      //       message.warning('拆分模式下只能选择一个,请先取消原来的数据')
      //       this.drawLine()
      //     }
      //   }
      // } else if (this.selectIndex == 3) {
      //   if (this.getAllSelectLayer().length == 0) {
      //     message.warning('请继续选择其他要合并地块')
      //     this.dkSelect[layer._leaflet_id] = layer
      //     this.setSelectStyle(layer)
      //   } else {
      //     if (this.getSelectLastData().toGeoJSON().properties.mainBodyId != layer.toGeoJSON().properties.mainBodyId) {
      //       message.error('请选择相同主体')
      //     } else {
      //       if (this.dkSelect[layer._leaflet_id]) {
      //         delete this.dkSelect[layer._leaflet_id]
      //         this.resetSingleStyle(layer)
      //       } else {
      //         this.dkSelect[layer._leaflet_id] = layer
      //         this.setSelectStyle(layer)
      //       }
      //     }
      //   }
      // }
    },
  },
}
</script>
