<template>
  <div style="width: 100%">
    <color-picker :value="value" @change="headleChangeColor" />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: String,
      default: '',
    },
  },
  methods: {
    headleChangeColor(e) {
      this.$emit('change', e)
    },
  },
}
</script>
<style lang="less" scoped>
.m-colorPicker {
  width: 120px;
  .colorBtn {
    width: 100% !important;
  }
}
/deep/.colorBtn {
  width: 80px !important;
}
/deep/.m-colorPicker .box {
  left: -197px;
}
</style>
