<script>
import { message } from 'ant-design-vue'
// import cloudbasae from '@cloudbase/js-sdk'
import { getAction, postAction } from '@/command/netTool'
// const app = cloudbasae.init({
//   env: 'dkinfo-8gm7gxzm0deb050f',
// })
// const db = app.database()
// const $ = db.command.aggregate

export default {
  methods: {
    // 获取当前所有图层数据
    getLayerData() {
      return new Promise((resolve, reject) => {
        getAction('/api/dataoperation/collectionMainDk/findDk').then((res) => {
          resolve(res)
        })
      })
    },
    // 保存图层数据
    saveLayerData(json) {
      return new Promise((resolve, reject) => {
        postAction('/api/dataoperation/collectionMainDk/addDk', json).then((e) => {
          message.success('保存成功')
          resolve(e)
        })
      })
    },
  },
}
</script>
