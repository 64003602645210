<script>
import { Select } from 'ant-design-vue'
export default {
  props: ['item', 'typeData', 'value', 'mode'],
  mounted() {
    if (this.item.defaultValue !== undefined) {
      this.$emit('change', this.value.toString() || this.item.defaultValue.toString())
    }
  },
  methods: {
    getValue() {
      if (this.item.sort) {
        return [].concat(this.value).sort((a, b) => Number(a) - Number(b))
      } else {
        if (this.value) {
          return this.value.split(',')
        } else {
          return []
        }
      }
    },
  },
  render() {
    return (
      <Select
        props={this.item.props}
        disabled={this.mode == 'detail'}
        value={this.getValue()}
        style={{ width: '100%' }}
        onChange={(e) => this.$emit('changeSelect', e.toString())}
        placeholder={this.item.placeholder || '请输入' + this.item.name}
        {...this.item.props}
      >
        {((this.item.typeData ?? this.typeData) || []).map((e) => {
          return <Select.Option value={e.value}>{e.name}</Select.Option>
        })}
      </Select>
    )
  },
}
</script>
