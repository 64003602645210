<script>
import { deleteAction } from '@/command/netTool'
import apiTool from '@/command/apiTool'
import { message } from 'ant-design-vue'
export default {
  methods: {
    deleteLayer(layer) {
      const json = layer.toGeoJSON()
      this.layer.eachLayer((e) => {
        const layerJson = e.toGeoJSON()
        if (layerJson.properties.id == json.properties.id) {
          delete this.dkSelect[layer._leaflet_id]
          this.layer.removeLayer(e)
        }
      })
    },
    // 删除当前选中的数据
    clearSelect() {
      const selectArr = this.getAllSelectLayer().map((e) => e.toGeoJSON().properties.id)
      this.layer.eachLayer((e) => {
        const json = e.toGeoJSON()
        if (selectArr.includes(json.properties.id)) {
          delete this.dkSelect[e._leaflet_id]
          this.layer.removeLayer(e)
        }
      })
    },
    // 设置单条为编辑模式
    setEditMode(layer) {
      layer.pm.enable({
        allowSelfIntersection: false,
        continueDrawing: false,
      })
    },
    // 获取对应图层的列表数据
    getAllLayer(layers) {
      const keys = Object.keys(layers)
      if (keys.length > 0) {
        return keys.map((key) => layers[key])
      } else {
        return []
      }
    },
    // 获取当前所有绘制图层数据
    getAllDrawLayer() {
      return this.getCacheLayer(false)
    },
    // 获取当前所有选中的图层
    getAllSelectLayer() {
      return this.getAllLayer(this.dkSelect)
    },
    // 清空所有当前已经选中的图层
    clearAllSelect() {
      this.getAllLayer(this.dkSelect).forEach((e) => {
        e.remove()
      })
      this.dkSelect = {}
    },
    // 清空所有绘制的图层
    clearDrawLayer() {
      this.getCacheLayer(false).forEach((e) => e.remove())
      this.drawLayerList = []
    },
    onCancel() {
      this.dkSelect = {}
      this.isFinish = true
      this.initMapLayer()
    },
    mergeSaveData(filterList) {
      return filterList.map((e) => {
        return {}
      })
    },
    filterLayerList() {
      let layerList = []
      this.layer.eachLayer((e) => {
        if (e.feature.geometry.type !== 'Point') {
          layerList.push(e)
        }
      })
      return layerList
    },
    getLayerListData(list) {
      return {
        type: 'FeatureCollection',
        features: list.map((layer) => {
          const latlng = layer.getCenter()
          const json = layer.toGeoJSON()
          const data = { ...json.properties, type: 'old' }
          return {
            type: 'FeatureCollection',
            features: [
              {
                ...json,
                properties: data,
              },
              {
                ...json,
                properties: data,
                geometry: {
                  type: 'Point',
                  coordinates: [latlng.lng, latlng.lat],
                },
              },
            ],
          }
        }),
      }
    },
    getMergeData(selectAll) {
      const unionData = geoUtil.unionPolygon(selectAll)
      const layer = selectAll[0]
      return {
        type: 'FeatureCollection',
        features: [
          {
            ...unionData,
            properties: {
              ...layer.properties,
            },
          },
          {
            ...turf.center(unionData),
            properties: {
              ...layer.properties,
            },
          },
        ],
      }
    },
    clearMaker() {
      if (this.markerLayer) {
        this.markerLayer.remove()
      }
    },
    saveData() {
      const json = this.getLayerListData(this.filterLayerList())
      this.saveLayerData(json).then(() => {
        this.clearMaker()
        this.dkInfo = null
        this.dkSelect = {}
        this.isFinish = true
        this.initMapLayerGeoJson(json)
        this.$emit('saveSuccess')
      })
    },
    removeAllSelect() {
      this.getAllSelectLayer().forEach((e) => {
        this.$delete(this.dkSelect, e._leaflet_id)
        this.layer.removeLayer(e)
      })
    },
    onSave() {
      if (this.getAllSelectLayer().length >= 2) {
        const selectAll = this.getAllSelectLayer().map((e) => e.toGeoJSON())
        const json = this.getMergeData(selectAll)
        this.removeAllSelect()
        this.layer.addData(json)
        this.saveData()
      } else {
        this.removeAllSelect()
        this.saveData()
      }
    },
    // 删除指定地块
    deleteDkForIds(ids) {
      return new Promise((resolve, reject) => {
        deleteAction(`/api/dataoperation/collectionMainDk/delDk`, { ids })
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject('删除地块失败')
          })
      })
    },
  },
}
</script>
